// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("./images/logo-karpa.svg");
var ___HTML_LOADER_IMPORT_1___ = require("./images/logo-bodega.png");
var ___HTML_LOADER_IMPORT_2___ = require("./images/img-anuncio.png");
// Module
var ___HTML_LOADER_REPLACER_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACER_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACER_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<!doctype html> <html lang=en> <head> <meta charset=UTF-8> <title>Karpa</title> </head> <header class=login> <div class=col-izq> <a class=logo_header href=index.html> <img src=" + ___HTML_LOADER_REPLACER_0___ + " alt=\"\"> </a> <label for=search> <input type=search placeholder=\"¿Qué estoy buscando hoy?\"> </label> </div> <div class=col-der> <button class=\"boton ingresar\"> ingresar </button> <a href=registro.html> <button class=\"ubicame boton naranja\"> quiero que me encuentren </button> </a> </div> </header> <body> <section class=inicio> <div class=col-izq> <ul> <a href=tienda.html> <li> <div class=hover_list> <img src=" + ___HTML_LOADER_REPLACER_1___ + " alt=\"\"> <div class=text_list> <h2 class=\"list_bodega plomo\">Bodega - Bazar \"Mari\"</h2> <h6 class=plomo-claro>calle siempre viva 153</h6> </div> </div> </li> </a> <a href=\"\"> <li> <div class=hover_list> <img src=" + ___HTML_LOADER_REPLACER_1___ + " alt=\"\"> <div class=text_list> <h2 class=\"list_bodega plomo\">Bodega - Bazar \"Mari\"</h2> <h6 class=plomo-claro>calle siempre viva 153</h6> </div> </div> </li> </a> <a href=\"\"> <li> <div class=hover_list> <img src=" + ___HTML_LOADER_REPLACER_1___ + " alt=\"\"> <div class=text_list> <h2 class=\"list_bodega plomo\">Bodega - Bazar \"Mari\"</h2> <h6 class=plomo-claro>calle siempre viva 153</h6> </div> </div> </li> </a> </ul> <div class=anuncio_img> <img src=" + ___HTML_LOADER_REPLACER_2___ + " alt=\"\"> </div> </div> <div class=col-der> <iframe width=500 height=500 src=\"https://maps.google.com/maps?q=Barranca&t=&z=17&ie=UTF8&iwloc=B&output=embed\" frameborder=0 scrolling=no marginheight=0 marginwidth=0></iframe> </div> </section> </body> </html> ";
// Exports
module.exports = code;